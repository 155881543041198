import './App.css'

import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { BaseProvider, DarkTheme } from 'baseui'
import { PLACEMENT, ToasterContainer } from 'baseui/toast'
import { createBrowserHistory } from 'history'
import Login from 'pages/Login/Login'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { getAuthToken, PrivateRoute, validToken } from 'services/auth'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

import { MainLayout } from './components/MainLayout/MainLayout'

const hist = createBrowserHistory()
const engine = new Styletron()

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: validToken(token) ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})

function App() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={DarkTheme}>
        <ApolloProvider client={client}>
          <ToasterContainer
            placement={PLACEMENT.bottom}
            autoHideDuration={5000}
          >
            <Router history={hist}>
              <Switch>
                <Redirect
                  from="/:url*(/+)"
                  to={hist.location.pathname.slice(0, -1)}
                />
                <PrivateRoute path="/admin" component={Home} />
                <Route path="/login" component={Login} />
              </Switch>
            </Router>
          </ToasterContainer>
        </ApolloProvider>
      </BaseProvider>
    </StyletronProvider>
  )
}

export default App

function Home() {
  return <MainLayout title="Welcome to Mr Yum SA Accounting" />
}
