import { Block } from 'baseui/block'
import { Button, SIZE } from 'baseui/button'
import {
  BooleanColumn,
  CustomColumn,
  StatefulDataTable,
  StringColumn,
} from 'baseui/data-table'
import { StyledLink } from 'baseui/link'
import { StyledSpinnerNext } from 'baseui/spinner'
import { LabelLarge } from 'baseui/typography'
import { DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { format } from 'date-fns'
import {
  SpecialItemPartsFragment,
  useVenueWithSpecialItemsQuery,
} from 'lib/gql'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useStyletron } from 'styletron-react'

const toRandDisplay = (value: number | null | undefined) => {
  if (!value) return ''
  return `R${(value / 100).toFixed(2)}`
}

const columns = [
  StringColumn({
    title: 'Name',
    mapDataToValue: (data: SpecialItemPartsFragment) => data.name,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  CustomColumn<{ payoutDate: Date | null }, {}>({
    title: 'Paid',
    mapDataToValue: (data: SpecialItemPartsFragment) => ({
      payoutDate: data.payout?.createdAt,
    }),
    renderCell: function Cell(props: any) {
      const [css] = useStyletron()
      return (
        <div
          className={css({
            backgroundColor: props.value.payoutDate ? 'green' : 'red',
            height: '12px',
            marginRight: '24px',
            width: '12px',
          })}
        />
      )
    },
  }),
  StringColumn({
    title: 'Description',
    mapDataToValue: (data: SpecialItemPartsFragment) => data.description,
  }),
  BooleanColumn({
    title: 'Taxable',
    mapDataToValue: (data: SpecialItemPartsFragment) => data.taxable,
  }),
  StringColumn({
    title: 'Price',
    mapDataToValue: (data: SpecialItemPartsFragment) =>
      toRandDisplay(data.totalInCents),
  }),
  StringColumn({
    title: 'Quantity',
    mapDataToValue: (data: SpecialItemPartsFragment) => data.quantity,
  }),
  StringColumn({
    title: 'Date',
    mapDataToValue: (data: SpecialItemPartsFragment) =>
      format(new Date(data.effectiveDate), DEFAULT_DATE_FORMAT),
  }),
]

const VenueSpecialItems: React.FC = () => {
  const { venueId } = useParams<{
    venueId: string
  }>()

  const { loading, error, data } = useVenueWithSpecialItemsQuery({
    variables: { venueId: venueId },
  })

  if (loading)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StyledSpinnerNext />
      </Block>
    )
  if (error)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Error
      </Block>
    )

  const specialItemsRows =
    data?.venue?.specialItems?.map((specialItem) => ({
      id: specialItem.id,
      data: specialItem,
    })) || []

  return (
    <Block height="100%">
      <Block width="100%" display="flex" alignItems="center">
        <LabelLarge>{data?.venue.name} - Special Items</LabelLarge>
        <Block
          as="div"
          overrides={{
            Block: {
              style: () => ({
                flexGrow: 1,
              }),
            },
          }}
        ></Block>
        <StyledLink href="special-items/add">
          <Button
            overrides={{
              Root: {
                style: () => ({
                  marginRight: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: 'auto',
                }),
              },
            }}
            size={SIZE.mini}
          >
            Add New
          </Button>
        </StyledLink>
      </Block>
      <StatefulDataTable
        columns={columns}
        rows={specialItemsRows}
        loading={loading}
        loadingMessage={'Loading data...'}
        emptyMessage={'No specialItems to display.'}
        searchable={false}
        filterable={false}
      />
    </Block>
  )
}

export { VenueSpecialItems }
