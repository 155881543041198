import { Datepicker } from 'baseui/datepicker'
import { FormControl } from 'baseui/form-control'
import { format, parse } from 'date-fns'
import { useField } from 'formik'
import React, { ComponentProps, useMemo } from 'react'

import { FormikControlProps } from './FormikControl'

export interface DateFieldProps
  extends ComponentProps<typeof Datepicker>,
    FormikControlProps {
  dateFormat?: string
}

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'

export const DateField: React.FC<DateFieldProps> = ({
  name,
  label,
  caption,
  disabled,
  dateFormat = DEFAULT_DATE_FORMAT,
  ...inputProps
}) => {
  const [field, meta, helpers] = useField(name)
  const hasError = !!(meta.touched && meta.error)

  // ensure string value is parsed into Date
  const value = useMemo(
    () =>
      field.value ? parse(field.value, dateFormat, new Date()) : field.value,
    [dateFormat, field.value],
  )

  return (
    <FormControl
      label={label}
      disabled={disabled}
      caption={caption}
      error={hasError ? meta.error : null}
    >
      <Datepicker
        {...inputProps}
        {...field}
        value={value}
        error={hasError}
        onChange={({ date }) => {
          helpers.setTouched(true)
          if (date) {
            helpers.setValue(format(date as Date, dateFormat))
          } else {
            helpers.setValue(null)
          }
        }}
      />
    </FormControl>
  )
}
