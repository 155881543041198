import { Block } from 'baseui/block'
import { StatefulDataTable, StringColumn } from 'baseui/data-table'
import { StyledSpinnerNext } from 'baseui/spinner'
import { DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { format } from 'date-fns'
import { PayoutPartsFragment, usePayoutsQuery } from 'lib/gql'
import * as React from 'react'

const toRandDisplay = (value: number | null | undefined) => {
  if (!value) return ''
  return `R${(value / 100).toFixed(2)}`
}

const columns = [
  StringColumn({
    title: 'Venue',
    mapDataToValue: (data: PayoutPartsFragment) => data.venue.name,
  }),
  StringColumn({
    title: 'Payout Total',
    mapDataToValue: (data: PayoutPartsFragment) =>
      toRandDisplay(data.totalInCents),
  }),
  StringColumn({
    title: 'End Date',
    mapDataToValue: (data: PayoutPartsFragment) =>
      format(new Date(data.toDate), DEFAULT_DATE_FORMAT),
  }),
  StringColumn({
    title: 'Batch Id',
    mapDataToValue: (data: PayoutPartsFragment) => data.batchId,
  }),
]

const Payouts: React.FC = () => {
  const { loading, error, data } = usePayoutsQuery()

  if (loading)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StyledSpinnerNext />
      </Block>
    )
  if (error)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Error
      </Block>
    )

  const payoutsRows =
    data?.payouts?.map((payout) => ({
      id: payout.id,
      data: payout,
    })) || []

  return (
    <Block height="100%">
      <StatefulDataTable
        columns={columns}
        rows={payoutsRows}
        loading={loading}
        loadingMessage={'Loading data...'}
        emptyMessage={'No payouts to display.'}
        searchable={false}
        filterable={false}
      />
    </Block>
  )
}

export { Payouts }
