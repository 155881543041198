import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export type ValidRole = 'admin'

export interface SessionUser {
  id: string
  role: ValidRole
}

export const AUTH_COOKIE = 'tip4change.token'
let savedUser: SessionUser | null = null

export const getAuthToken = () => {
  const jwtToken = Cookies.get(AUTH_COOKIE)
  return jwtToken
}

export const setToken = (token: string | null | undefined) => {
  if (token) {
    sessionUser(token)
    Cookies.set(AUTH_COOKIE, token, { expires: 30 })
  }
}

export const clearToken = () => {
  Cookies.remove(AUTH_COOKIE)
  savedUser = null
}

export const isLoggedIn = (token: string | null | undefined) => {
  if (validToken(token)) {
    return true
  } else {
    clearToken()
    return false
  }
}

export const validToken = (token: string | null | undefined) => {
  if (token) {
    const currentTime = Date.now() / 1000
    const jwt: any = jwtDecode(token)
    if (jwt.exp < currentTime) {
      clearToken()
      return false
    }
    return true
  }
  return false
}

export const sessionUser = (token: string | null | undefined) => {
  if (savedUser) {
    return savedUser
  }
  if (token) {
    if (!validToken(token)) {
      clearToken()
      return null
    }
    const jwt: any = jwtDecode(token)
    savedUser = { id: jwt.id, role: jwt.role }
    return savedUser
  }
  return null
}

interface Props extends RouteProps {
  component: React.ComponentType<any>
}

export function PrivateRoute({ component: Component, ...rest }: Props) {
  const token = getAuthToken()
  const user = sessionUser(token)
  const authed = Boolean(validToken(token) && user)
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
