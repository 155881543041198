import { Block } from 'baseui/block'
import {
  CustomColumn,
  StatefulDataTable,
  StringColumn,
} from 'baseui/data-table'
import { StyledSpinnerNext } from 'baseui/spinner'
import { DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { TableButton } from 'components/Table/TableButton'
import { format } from 'date-fns'
import { InvoicePartsFragment, useInvoicesQuery } from 'lib/gql'
import * as React from 'react'

const columns = [
  CustomColumn({
    title: 'Venue',
    mapDataToValue: (data: InvoicePartsFragment) => ({
      id: data.id,
      name: data.venue.name,
    }),
    renderCell: ({ value }: any) => {
      return (
        <TableButton
          href={`${process.env.REACT_APP_API_BASE}/invoices/download/?id=${value.id}`}
          title={value.name}
          target="_blank"
        />
      )
    },
  }),
  StringColumn({
    title: 'End Date',
    mapDataToValue: (data: InvoicePartsFragment) =>
      format(new Date(data.toDate), DEFAULT_DATE_FORMAT),
  }),
]

const Invoices: React.FC = () => {
  const { loading, error, data } = useInvoicesQuery()

  if (loading)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StyledSpinnerNext />
      </Block>
    )
  if (error)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Error
      </Block>
    )

  const invoicesRows =
    data?.invoices?.map((invoice) => ({
      id: invoice.id,
      data: invoice,
    })) || []

  return (
    <Block height="100%">
      <StatefulDataTable
        columns={columns}
        rows={invoicesRows}
        loading={loading}
        loadingMessage={'Loading data...'}
        emptyMessage={'No venues to display.'}
        searchable={false}
        filterable={false}
      />
    </Block>
  )
}

export { Invoices }
