import { FormControl, FormControlOverrides } from 'baseui/form-control'
import { Input, InputProps } from 'baseui/input'
import { useField } from 'formik'
import React from 'react'

import { FormikControlProps } from './FormikControl'

export type InputFieldProps = InputProps &
  FormikControlProps & { formControlOverRides?: FormControlOverrides }

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  caption,
  disabled,
  formControlOverRides,
  ...inputProps
}) => {
  const [field, meta] = useField(name)
  const hasError = !!(meta.touched && meta.error)
  const isGood = !!(meta.value && !meta.error)
  return (
    <FormControl
      label={label}
      disabled={disabled}
      caption={caption}
      error={hasError ? meta.error : null}
      overrides={formControlOverRides}
    >
      <Input {...inputProps} {...field} error={hasError} positive={isGood} />
    </FormControl>
  )
}
