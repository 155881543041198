import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Card } from 'baseui/card'
import { DateField, DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { Form, Formik } from 'components/Form/Formik'
import { Yup } from 'components/Form/Yup'
import { format, subDays } from 'date-fns'
import * as React from 'react'

const ReportRequestSchema = Yup.object()
  .noUnknown(false)
  .shape({
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().min(Yup.ref('startDate')).required('Required'),
  })

type ReportRequestInput = {
  startDate: string
  endDate: string
}

const AccountingReport: React.FC = () => {
  const [generatingReport, setGeneratingReport] = React.useState(false)

  const handleSubmit = async (reportRequest: ReportRequestInput) => {
    setGeneratingReport(true)
    fetch(
      `${process.env.REACT_APP_API_BASE}/reports/create?start_date=${reportRequest.startDate}&end_date=${reportRequest.endDate}`,
      {
        method: 'GET',
      },
    )
      .then((res) => res.blob())
      .then((blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Mr Yum SA Report ${reportRequest.startDate} - ${reportRequest.endDate}.csv`,
        )
        // 3. Append to html page
        document.body.appendChild(link)
        // 4. Force download
        link.click()
        // 5. Clean up and remove the link
        link.parentNode?.removeChild(link)
        setGeneratingReport(false)
      })
  }

  const initialValues = {
    startDate: format(subDays(new Date(), 14), DEFAULT_DATE_FORMAT),
    endDate: format(new Date(), DEFAULT_DATE_FORMAT),
  } as ReportRequestInput

  return (
    <Card title="Accounting Report">
      <Block>
        <Formik<ReportRequestInput>
          onSubmit={handleSubmit}
          validationSchema={ReportRequestSchema}
          initialValues={initialValues}
        >
          {({ isSubmitting }) => (
            <Form>
              <DateField name="startDate" label="Start Date" />
              <DateField name="endDate" label="End Date" />
              <Button
                isLoading={isSubmitting}
                disabled={generatingReport}
                type="submit"
              >
                Download
              </Button>
            </Form>
          )}
        </Formik>
      </Block>
    </Card>
  )
}

export { AccountingReport }
