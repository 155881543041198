import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Card, StyledAction, StyledBody } from 'baseui/card'
import { useHistory } from 'react-router-dom'

import { Form, Formik, InputField, Yup } from '../../components/Form'
import { useUserSignInMutation } from '../../lib/gql'
import { setToken } from '../../services/auth'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(300, 'Too Long!')
    .required('Required'),
})

export default function Login() {
  const history = useHistory()

  const [signIn] = useUserSignInMutation()

  return (
    <>
      <Block
        overrides={{
          Block: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.backgroundPrimary,
              height: '100vh',
              display: 'flex',
              flexFirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }),
          },
        }}
      >
        <Card>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              signIn({
                variables: {
                  ...values,
                },
              })
                .then((result) => {
                  setToken(result.data?.userSignIn.token)
                  history.push('/admin')
                })
                .catch((_error) => {
                  setSubmitting(false)
                  // enqueueSnackbar(error.message, {
                  //   variant: 'error',
                  // })
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <>
                  <StyledBody>
                    Login to the Mr Yum SA Accounting Platform
                    <InputField type="email" name="email" placeholder="Email" />
                    <InputField
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </StyledBody>
                  <StyledAction disableSpacing>
                    <Button disabled={isSubmitting} type="submit">
                      Login
                    </Button>
                  </StyledAction>
                </>
              </Form>
            )}
          </Formik>
        </Card>
      </Block>
    </>
  )
}
