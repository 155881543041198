import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { toaster } from 'baseui/toast'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import {
  CheckboxField,
  Form,
  Formik,
  InputField,
  Yup,
} from '../../components/Form'
import {
  useUpdateVenueMutation,
  useVenueQuery,
  VenueUpdateInput,
} from '../../lib/gql'

const VenueInfoSchema = Yup.object()
  .noUnknown(false)
  .shape({
    payable: Yup.bool().required('Required'),
    normalFeePercentage: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    quenchFeePercentage: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    quenchPortionPercentage: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    normalFeeFixed: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    quenchFeeFixed: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    quenchFlatFee: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    refundFee: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    minimumFeeEnabled: Yup.bool().required('Required'),
    minimumFee: Yup.number().nullable().when('minimumFeeEnabled', {
      is: true,
      then: Yup.number().required(),
    }),
    fixedFeeThreshold: Yup.number().nullable().when('payable', {
      is: true,
      then: Yup.number().required(),
    }),
    bankAccountNumber: Yup.string().nullable().when('payable', {
      is: true,
      then: Yup.string().required(),
    }),
    bankAccountHoldersName: Yup.string().nullable().when('payable', {
      is: true,
      then: Yup.string().required(),
    }),
    branchCode: Yup.string().nullable().when('payable', {
      is: true,
      then: Yup.string().required(),
    }),
  })

const Venue: React.FC = () => {
  const { venueId } = useParams<{ venueId: string }>()

  const { loading, error, data } = useVenueQuery({
    variables: {
      id: venueId,
    },
  })

  const [updateVenue] = useUpdateVenueMutation()

  const handleSubmit = async (venueUpdateInput: VenueUpdateInput) => {
    const data = {
      ...venueUpdateInput,
      id: venueId,
    } as VenueUpdateInput

    updateVenue({ variables: { data } })
      .then(() => {
        toaster.positive('Venue Updated', {})
      })
      .catch((error) => {
        toaster.negative(error.message, {})
      })
  }

  if (loading) return <Block>Loading</Block>
  if (error) return <Block>error</Block>

  const venue = data?.venue

  if (!venue) return <Block>error</Block>

  const initialValues = {
    id: venueId,
    name: venue.name,
    email: venue.email,
    payable: venue.payable,
    address: venue.address,
    contactNumber: venue.contactNumber,
    vatNumber: venue.vatNumber,
    normalFeePercentage: venue.normalFeePercentage,
    quenchFeePercentage: venue.quenchFeePercentage,
    quenchPortionPercentage: venue.quenchPortionPercentage,
    normalFeeFixed: venue.normalFeeFixed,
    quenchFeeFixed: venue.quenchFeeFixed,
    quenchFlatFee: venue.quenchFlatFee,
    refundFee: venue.refundFee,
    minimumFee: venue.minimumFee,
    fixedFeeThreshold: venue.fixedFeeThreshold,
    bankAccountHoldersName: venue.bankAccountHoldersName,
    minimumFeeEnabled: venue.minimumFeeEnabled,
    bankAccountNumber: venue.bankAccountNumber,
    branchCode: venue.branchCode,
  } as VenueUpdateInput

  return (
    <Block height="100%">
      <Formik<VenueUpdateInput>
        onSubmit={handleSubmit}
        validationSchema={VenueInfoSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <CheckboxField name="payable" label="Enabled?" />
            <InputField name="email" label="email" />
            <InputField name="address" label="address" />
            <InputField name="contactNumber" label="contactNumber" />
            <InputField name="vatNumber" label="vatNumber" />
            <InputField
              name="normalFeePercentage"
              label="normalFeePercentage"
              type="number"
            />
            <InputField
              name="quenchFeePercentage"
              label="quenchFeePercentage"
              type="number"
            />
            <InputField
              name="quenchPortionPercentage"
              label="quenchPortionPercentage"
              type="number"
            />
            <InputField
              name="normalFeeFixed"
              label="normalFeeFixed"
              type="number"
            />
            <InputField
              name="quenchFeeFixed"
              label="quenchFeeFixed"
              type="number"
            />
            <InputField
              name="quenchFlatFee"
              label="quenchFlatFee"
              type="number"
            />
            <InputField name="refundFee" label="refundFee" type="number" />
            <InputField name="minimumFee" label="minimumFee" type="number" />
            <InputField
              name="fixedFeeThreshold"
              label="fixedFeeThreshold"
              type="number"
            />
            <CheckboxField name="minimumFeeEnabled" label="minimumFeeEnabled" />
            <InputField
              name="bankAccountHoldersName"
              label="bankAccountHoldersName"
            />
            <InputField name="bankAccountNumber" label="bankAccountNumber" />
            <InputField name="branchCode" label="branchCode" />
            <Button isLoading={isSubmitting} disabled={!dirty} type="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Block>
  )
}

export { Venue }
