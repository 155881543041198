import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { toaster } from 'baseui/toast'
import { LabelLarge } from 'baseui/typography'
import { DateField, DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { SelectField } from 'components/Form/SelectField'
import { format } from 'date-fns'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  CheckboxField,
  Form,
  Formik,
  InputField,
  Yup,
} from '../../../components/Form'
import {
  SpecialItemInput,
  SpecialItemKind,
  useAddOrUpdateSpecialItemMutation,
  useSpecialItemQuery,
} from '../../../lib/gql'

const SpecialItemSchema = Yup.object()
  .noUnknown(false)
  .shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    taxable: Yup.bool().required('Required'),
    totalInCents: Yup.number().required('Required'),
    quantity: Yup.number().required('Required'),
    effectiveDate: Yup.date().required('Required'),
  })

const VenueSpecialItem: React.FC = () => {
  const { specialItemId, venueId } = useParams<{
    specialItemId: string
    venueId: string
  }>()
  const { loading, error, data } = useSpecialItemQuery({
    variables: {
      id: specialItemId,
      venueId: venueId,
    },
  })
  const history = useHistory()

  const [addOrUpdateSpecialItem] = useAddOrUpdateSpecialItemMutation()

  const handleSubmit = async (specialItemInput: SpecialItemInput) => {
    const data = {
      ...specialItemInput,
      id: specialItemId,
    } as SpecialItemInput

    const result = await addOrUpdateSpecialItem({ variables: { data } })
    if (result.errors) {
      // eslint-disable-next-line no-console
      console.error(result.errors)
      toaster.negative(JSON.stringify(result.errors), {})
    } else {
      toaster.positive('Special Item Added', {})
      history.push('.')
    }
  }

  if (loading && specialItemId) return <Block>Loading</Block>
  if (error && specialItemId) return <Block>error</Block>

  const specialItem = data?.specialItem

  const initialValues = {
    id: specialItemId,
    name: specialItem?.name,
    description: specialItem?.description,
    specialItemKind: 'DEBIT',
    taxable: specialItem?.taxable ?? true,
    totalInCents: specialItem?.totalInCents,
    quantity: specialItem?.quantity ?? 1,
    effectiveDate:
      specialItem?.effectiveDate ?? format(new Date(), DEFAULT_DATE_FORMAT),
    venueId: venueId,
  } as SpecialItemInput

  return (
    <Block height="100%">
      <LabelLarge>{data?.venue.name} - Add Special Item</LabelLarge>
      <Formik<SpecialItemInput>
        onSubmit={handleSubmit}
        validationSchema={SpecialItemSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting, dirty }) => (
          <Form>
            <InputField name="name" label="name" />
            <InputField name="description" label="Description" />
            <SelectField
              label="Type"
              name="specialItemKind"
              optionsEnum={SpecialItemKind}
              clearable={false}
            />
            <CheckboxField name="taxable" label="Taxable" />
            <InputField
              name="totalInCents"
              label="Total In Cents"
              type="number"
            />
            <InputField name="quantity" label="Quantity" type="number" />
            <DateField name="effectiveDate" label="Effective Date" />
            <Button isLoading={isSubmitting} disabled={!dirty} type="submit">
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Block>
  )
}

export { VenueSpecialItem }
