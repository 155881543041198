import { Accordion, Panel } from 'baseui/accordion'
import { Block } from 'baseui/block'
import { Button, SIZE } from 'baseui/button'
import { ArrowLeft } from 'baseui/icon'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { SideBarButton } from './SideBarButton'

const BackButton: React.FC = React.memo(() => {
  const history = useHistory()
  if (['/admin', '/login'].includes(history.location.pathname)) {
    return <Block paddingTop="60px" />
  }
  return (
    <Block
      height="60px"
      display="flex"
      justifyContent="right"
      alignItems="center"
      paddingRight="10px"
    >
      <Button
        size={SIZE.compact}
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log('clicked')
          history.goBack()
        }}
      >
        <ArrowLeft />
      </Button>
    </Block>
  )
})

const SideBar: React.FC = React.memo(() => {
  return (
    <Block
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      width="280px"
      overflow="auto"
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundPrimary,
          }),
        },
      }}
    >
      <BackButton />
      <Block
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        as="nav"
        paddingBottom="64px"
      >
        <Accordion>
          <Panel key="P1" title="Reports">
            <SideBarButton title="View" href="/admin/reports" />
          </Panel>
          <Panel key="P2" title="Payouts">
            <SideBarButton title="Create" href="/admin/payouts/create" />
            <SideBarButton title="View" href="/admin/payouts" />
            <SideBarButton title="Peach Batches" href="/admin/payouts/peach" />
          </Panel>
          <Panel key="P3" title="Invoices">
            <SideBarButton title="View" href="/admin/invoices" />
          </Panel>
          <Panel key="P4" title="Venues">
            <SideBarButton title="Manage" href="/admin/venues" />
          </Panel>
          <Panel key="P5" title="Special Items">
            <SideBarButton title="View" href="/admin/special-items" />
          </Panel>
        </Accordion>
      </Block>
      <Block flex="1" />
    </Block>
  )
})

export { SideBar }
