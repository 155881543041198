import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import {
  BooleanColumn,
  CustomColumn,
  StatefulDataTable,
  StringColumn,
} from 'baseui/data-table'
import { StyledSpinnerNext } from 'baseui/spinner'
import { TableButton } from 'components/Table/TableButton'
import { useVenuesQuery, VenuePartsFragment } from 'lib/gql'
import * as React from 'react'

const toPercentageDisplay = (percentage: number | null | undefined) => {
  if (!percentage) return ''
  return `${(percentage / 100).toFixed(2)}%`
}

const toRandDisplay = (value: number | null | undefined) => {
  if (!value) return ''
  return `R${(value / 100).toFixed(2)}`
}

const columns = [
  CustomColumn({
    title: 'Name',
    mapDataToValue: (data: VenuePartsFragment) => ({
      id: data.id,
      name: data.name,
    }),
    renderCell: ({ value }: any) => {
      return (
        <TableButton href={`/admin/venues/${value.id}`} title={value.name} />
      )
    },
  }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  CustomColumn<{ eftPayable: boolean; payable: boolean }, {}>({
    title: 'Payable',
    mapDataToValue: (data: VenuePartsFragment) => ({
      eftPayable: data.eftPayable,
      payable: data.payable,
    }),
    renderCell: function Cell(props: any) {
      const [css] = useStyletron()
      return (
        <div
          className={css({
            backgroundColor:
              props.value.payable && props.value.eftPayable ? 'green' : 'red',
            height: '12px',
            marginRight: '24px',
            width: '12px',
          })}
        />
      )
    },
  }),
  CustomColumn({
    title: 'Special Items',
    mapDataToValue: (data: VenuePartsFragment) => ({
      id: data.id,
    }),
    renderCell: ({ value }: any) => {
      return (
        <TableButton
          href={`/admin/venues/${value.id}/special-items`}
          title="Modify"
        />
      )
    },
  }),
  StringColumn({
    title: 'Normal % Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toPercentageDisplay(data.normalFeePercentage),
  }),
  StringColumn({
    title: 'Quench % Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toPercentageDisplay(data.quenchFeePercentage),
  }),
  StringColumn({
    title: 'Quench Portion',
    mapDataToValue: (data: VenuePartsFragment) =>
      toPercentageDisplay(data.quenchPortionPercentage),
  }),
  StringColumn({
    title: 'Normal Fixed Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data.normalFeeFixed),
  }),
  StringColumn({
    title: 'Quench Fixed Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data.quenchFeeFixed),
  }),
  StringColumn({
    title: 'Quench Flat Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data?.quenchFlatFee),
  }),
  StringColumn({
    title: 'Refund Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data?.refundFee),
  }),
  StringColumn({
    title: 'Min Fee',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data?.minimumFee),
  }),
  StringColumn({
    title: 'Fixed Threshold',
    mapDataToValue: (data: VenuePartsFragment) =>
      toRandDisplay(data?.fixedFeeThreshold),
  }),
  BooleanColumn({
    title: 'Min Fee Enabled',
    mapDataToValue: (data: VenuePartsFragment) => data.minimumFeeEnabled,
  }),
]

const Venues: React.FC = () => {
  const { loading, error, data } = useVenuesQuery()

  if (loading)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StyledSpinnerNext />
      </Block>
    )
  if (error)
    return (
      <Block
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        Error
      </Block>
    )

  const venueRows =
    data?.venues?.map((venue) => ({
      id: venue.id,
      data: venue,
    })) || []

  return (
    <Block height="100%">
      <StatefulDataTable
        columns={columns}
        rows={venueRows}
        loading={loading}
        loadingMessage={'Loading data...'}
        emptyMessage={'No venues to display.'}
        searchable={false}
        filterable={false}
      />
    </Block>
  )
}

export { Venues }
