import { FormControl } from 'baseui/form-control'
import { OnChangeParams, Option, Select, SelectProps } from 'baseui/select'
import { useField } from 'formik'
import { ReactNode, useCallback } from 'react'
import { humanize } from 'support/strings'

import { FormikControlProps } from './FormikControl'

// we don't support baseui's option groups currently, so Options is a flat array
type Options = ReadonlyArray<Option>
// others for when labelKey or valueKey have been customised
type Value<T = string | number> =
  | T
  | { id?: T; label?: ReactNode; [others: string]: any }

type OptionsEnumType = Exclude<any, undefined | null | any[]> // enums aren't subtypes of any other type
interface EnumOptionsProps {
  optionsEnum: OptionsEnumType
  options?: never
}

interface OptionsProps {
  optionsEnum?: never
  options: Options
}

type MultiSelectProp<TValue> = TValue extends any[]
  ? {
      multi: true
    }
  : {
      multi?: false
    }

type FieldValue<
  TMulti extends boolean | undefined,
  TValue
> = TMulti extends true ? Value<TValue>[] : Value<TValue>

export type SelectFieldProps<
  TValue extends Value | Value[]
> = MultiSelectProp<TValue> &
  (EnumOptionsProps | OptionsProps) &
  Omit<SelectProps, 'options' | 'value'> &
  FormikControlProps

function hasOptionKeys(
  value: any,
  valueKey: string,
  labelKey: string,
): value is Option {
  return typeof value === 'object' && valueKey in value && labelKey in value
}

export function SelectField<TValue extends Value | Value[] = any>({
  name,
  label,
  caption,
  disabled,
  optionsEnum,
  options,
  multi,
  valueKey = 'id',
  labelKey = 'label',
  ...selectProps
}: SelectFieldProps<TValue>) {
  const [field, meta, helpers] = useField<FieldValue<typeof multi, TValue>>(
    name,
  )
  const hasError = !!(meta.touched && meta.error)

  const getOptions = useCallback(
    (
      options: Options | undefined,
      optionsEnum: OptionsEnumType | undefined,
    ): Options => {
      if (optionsEnum) {
        return Object.values(optionsEnum).map((value) => ({
          [valueKey]: value,
          [labelKey]: humanize(String(value)),
        }))
      }
      if (options) {
        return options
      }
      return []
    },
    [labelKey, valueKey],
  )

  const getSelectValue = useCallback(
    (
      fieldValue: Value<TValue> | Value<TValue>[],
      options: Options,
    ): Options | undefined => {
      if (!fieldValue) return undefined
      return (Array.isArray(fieldValue) ? fieldValue : [fieldValue]).map(
        (value) => {
          // value exists in options, render the option itself
          const existingOption = options.find(
            (option) => option && option[valueKey] === value,
          )
          if (existingOption) {
            return existingOption
          }
          if (hasOptionKeys(value, valueKey, labelKey)) {
            return [
              {
                [valueKey]: value[labelKey],
                [labelKey]: value[valueKey],
              },
            ]
          }
          return {
            [valueKey]: value,
            [labelKey]: value,
          }
        },
      )
    },
    [labelKey, valueKey],
  )

  const getFormValue = useCallback(
    (params: OnChangeParams, _options: Options) => {
      if (!multi) {
        return params.value[0] && params.value[0][valueKey]
      } else {
        return params.value.map((thisValue) => thisValue[valueKey])
      }
    },
    [multi, valueKey],
  )

  const onChange = useCallback(
    (params: OnChangeParams) => {
      const selectOptions = getOptions(options, optionsEnum)
      helpers.setValue(getFormValue(params, selectOptions))
    },
    [getOptions, options, optionsEnum, helpers, getFormValue],
  )

  const onBlur = useCallback(() => {
    helpers.setTouched(true)
  }, [helpers])

  const selectOptions = getOptions(options, optionsEnum)
  const value = getSelectValue(field.value, selectOptions)

  return (
    <>
      <FormControl
        label={label}
        disabled={disabled}
        caption={caption}
        error={hasError ? meta.error : null}
      >
        <Select
          {...selectProps}
          {...field}
          valueKey={valueKey}
          labelKey={labelKey}
          multi={multi}
          options={selectOptions}
          value={value}
          error={hasError}
          onBlur={onBlur}
          onChange={onChange}
          overrides={{
            Input: {
              props: {
                name: field.name,
              },
            },
          }}
        />
      </FormControl>
    </>
  )
}
