/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type ChangePasswordFromLinkInput = {
  accessCode: Scalars['String'];
  password: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
};


export type DoPayoutInput = {
  endDate: Scalars['DateTime'];
  venueId: Scalars['String'];
};

export type DoPayoutsInput = {
  endDate: Scalars['DateTime'];
};

export type GenerateInvoiceInput = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  venueId: Scalars['String'];
};

export type Invoice = {
  __typename?: 'Invoice';
  fileName: Scalars['String'];
  id: Scalars['String'];
  toDate: Scalars['DateTime'];
  venue: Venue;
  venueId: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  description?: Maybe<Scalars['String']>;
  externalPosGroupId?: Maybe<Scalars['String']>;
  externalPosId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  menuItemId?: Maybe<Scalars['String']>;
  menuItemModifierId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  originalPriceInCents: Scalars['Float'];
  parentId?: Maybe<Scalars['String']>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  preDiscountPrice: Scalars['Float'];
  priceInCents: Scalars['Float'];
  quantity: Scalars['Float'];
  shortName?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addOrUpdateSpecialItem: SpecialItem;
  createOrModifyUser: User;
  doPayout: Payout;
  doPayouts: Array<Payout>;
  resetPasswordByEmail: Scalars['Boolean'];
  resetPasswordById: Scalars['Boolean'];
  updateVenue: Venue;
  userPasswordChange: UserTokenDto;
  userSetPassword: Scalars['Boolean'];
  userSignIn: UserTokenDto;
};


export type MutationAddOrUpdateSpecialItemArgs = {
  data: SpecialItemInput;
};


export type MutationCreateOrModifyUserArgs = {
  data: CreateUserInput;
};


export type MutationDoPayoutArgs = {
  data: DoPayoutInput;
};


export type MutationDoPayoutsArgs = {
  data: DoPayoutsInput;
};


export type MutationResetPasswordByEmailArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordByIdArgs = {
  id: Scalars['String'];
};


export type MutationUpdateVenueArgs = {
  data: VenueUpdateInput;
};


export type MutationUserPasswordChangeArgs = {
  data: ChangePasswordFromLinkInput;
};


export type MutationUserSetPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUserSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
  deliveryId: Scalars['String'];
  id: Scalars['String'];
  orderingType: OrderingType;
  payout?: Maybe<Payout>;
  payoutId?: Maybe<Scalars['String']>;
  status: OrderStatus;
  totalInCents: Scalars['Float'];
  venueId: Scalars['String'];
};

export enum OrderStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Refunded = 'REFUNDED',
  Rejected = 'REJECTED'
}

export enum OrderingType {
  Catering = 'CATERING',
  Counter = 'COUNTER',
  Delivery = 'DELIVERY',
  DineIn = 'DINE_IN',
  InVenue = 'IN_VENUE',
  Menu = 'MENU',
  PickUp = 'PICK_UP'
}

export type Payout = {
  __typename?: 'Payout';
  bankAccountNumber: Scalars['String'];
  batchId: Scalars['String'];
  branchCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  fixedFeeThreshold?: Maybe<Scalars['Float']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  normalFeeFixed?: Maybe<Scalars['Float']>;
  normalFeePercentage?: Maybe<Scalars['Float']>;
  normalFixedTotalFee?: Maybe<Scalars['Float']>;
  normalThresholdOrders?: Maybe<Scalars['Float']>;
  normalTotal?: Maybe<Scalars['Float']>;
  normalTotalFee?: Maybe<Scalars['Float']>;
  orders?: Maybe<Array<Order>>;
  payoutProcessor: PayoutProcessorType;
  quenchFeeFixed?: Maybe<Scalars['Float']>;
  quenchFeePercentage?: Maybe<Scalars['Float']>;
  quenchFixedTotalFee?: Maybe<Scalars['Float']>;
  quenchFlatFee?: Maybe<Scalars['Float']>;
  quenchFlatTotalFee?: Maybe<Scalars['Float']>;
  quenchOrders?: Maybe<Scalars['Float']>;
  quenchPortionPercentage?: Maybe<Scalars['Float']>;
  quenchThresholdOrders?: Maybe<Scalars['Float']>;
  quenchTotal?: Maybe<Scalars['Float']>;
  quenchTotalFee?: Maybe<Scalars['Float']>;
  refundFee?: Maybe<Scalars['Float']>;
  refundTotalFee?: Maybe<Scalars['Float']>;
  refunds?: Maybe<Scalars['Float']>;
  specialItems?: Maybe<Array<SpecialItem>>;
  status: PayoutStatus;
  toDate?: Maybe<Scalars['DateTime']>;
  totalInCents: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  venue: Venue;
};

export enum PayoutProcessorType {
  Peach = 'PEACH'
}

export enum PayoutStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Requested = 'REQUESTED'
}

export type PeachPayout = {
  __typename?: 'PeachPayout';
  batchId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  fileName: Scalars['String'];
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  generateInvoice: Scalars['Boolean'];
  invoices: Array<Invoice>;
  me: User;
  orders: Array<Order>;
  payouts: Array<Payout>;
  peachPayouts: Array<PeachPayout>;
  roles: Array<Scalars['String']>;
  specialItem?: Maybe<SpecialItem>;
  specialItems: Array<SpecialItem>;
  user?: Maybe<User>;
  userWithAccessCode: User;
  users: Array<User>;
  venue: Venue;
  venues: Array<Venue>;
};


export type QueryGenerateInvoiceArgs = {
  data: GenerateInvoiceInput;
};


export type QuerySpecialItemArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserWithAccessCodeArgs = {
  accessCode: Scalars['String'];
};


export type QueryVenueArgs = {
  id: Scalars['String'];
};

export type RevenueReport = {
  __typename?: 'RevenueReport';
  dates: Array<Scalars['DateTime']>;
  series: Array<Scalars['Float']>;
};

export type SpecialItem = {
  __typename?: 'SpecialItem';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  payout?: Maybe<Payout>;
  payoutId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  shortName?: Maybe<Scalars['String']>;
  specialItemKind: SpecialItemKind;
  taxable: Scalars['Boolean'];
  totalInCents: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  venue?: Maybe<Venue>;
  venueId: Scalars['String'];
};

export type SpecialItemInput = {
  description?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payoutId?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  shortName?: Maybe<Scalars['String']>;
  specialItemKind: SpecialItemKind;
  taxable: Scalars['Boolean'];
  totalInCents: Scalars['Float'];
  venueId: Scalars['String'];
};

export enum SpecialItemKind {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type User = {
  __typename?: 'User';
  accessCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type UserTokenDto = {
  __typename?: 'UserTokenDto';
  token: Scalars['String'];
};

export type Venue = {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  bankAccountHoldersName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  deliveryFeeFixed?: Maybe<Scalars['Float']>;
  deliveryFeePercentage?: Maybe<Scalars['Float']>;
  dineInFeeFixed?: Maybe<Scalars['Float']>;
  dineInFeePercentage?: Maybe<Scalars['Float']>;
  eftPayable: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  fixedFeeThreshold?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  minimumFee?: Maybe<Scalars['Float']>;
  minimumFeeEnabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  normalFeeFixed?: Maybe<Scalars['Float']>;
  normalFeePercentage?: Maybe<Scalars['Float']>;
  payable?: Maybe<Scalars['Boolean']>;
  payoutProcessor: PayoutProcessorType;
  pickupFeeFixed?: Maybe<Scalars['Float']>;
  pickupFeePercentage?: Maybe<Scalars['Float']>;
  quenchFeeFixed?: Maybe<Scalars['Float']>;
  quenchFeePercentage?: Maybe<Scalars['Float']>;
  quenchFlatFee?: Maybe<Scalars['Float']>;
  quenchPortionPercentage?: Maybe<Scalars['Float']>;
  refundFee?: Maybe<Scalars['Float']>;
  specialItems: Array<SpecialItem>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type VenueUpdateInput = {
  address?: Maybe<Scalars['String']>;
  bankAccountHoldersName?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  branchCode?: Maybe<Scalars['String']>;
  contactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fixedFeeThreshold?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  minimumFee?: Maybe<Scalars['Float']>;
  minimumFeeEnabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  normalFeeFixed?: Maybe<Scalars['Float']>;
  normalFeePercentage?: Maybe<Scalars['Float']>;
  payable?: Maybe<Scalars['Boolean']>;
  quenchFeeFixed?: Maybe<Scalars['Float']>;
  quenchFeePercentage?: Maybe<Scalars['Float']>;
  quenchFlatFee?: Maybe<Scalars['Float']>;
  quenchPortionPercentage?: Maybe<Scalars['Float']>;
  refundFee?: Maybe<Scalars['Float']>;
  vatNumber?: Maybe<Scalars['String']>;
};

export type InvoicePartsFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'toDate'>
  & { venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ) }
);

export type InvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & InvoicePartsFragment
  )> }
);

export type UserSignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type UserSignInMutation = (
  { __typename?: 'Mutation' }
  & { userSignIn: (
    { __typename?: 'UserTokenDto' }
    & Pick<UserTokenDto, 'token'>
  ) }
);

export type DoPayoutsMutationVariables = Exact<{
  data: DoPayoutsInput;
}>;


export type DoPayoutsMutation = (
  { __typename?: 'Mutation' }
  & { doPayouts: Array<(
    { __typename?: 'Payout' }
    & PayoutPartsFragment
  )> }
);

export type PayoutPartsFragment = (
  { __typename?: 'Payout' }
  & Pick<Payout, 'id' | 'toDate' | 'batchId' | 'totalInCents'>
  & { venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ) }
);

export type PayoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayoutsQuery = (
  { __typename?: 'Query' }
  & { payouts: Array<(
    { __typename?: 'Payout' }
    & PayoutPartsFragment
  )> }
);

export type PeachPayoutPartsFragment = (
  { __typename?: 'PeachPayout' }
  & Pick<PeachPayout, 'id' | 'batchId' | 'fileName' | 'createdAt'>
);

export type PeachPayoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type PeachPayoutsQuery = (
  { __typename?: 'Query' }
  & { peachPayouts: Array<(
    { __typename?: 'PeachPayout' }
    & PeachPayoutPartsFragment
  )> }
);

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'totalInCents'>
  )> }
);

export type SpecialItemPartsFragment = (
  { __typename?: 'SpecialItem' }
  & Pick<SpecialItem, 'id' | 'name' | 'specialItemKind' | 'shortName' | 'description' | 'taxable' | 'totalInCents' | 'quantity' | 'createdAt' | 'effectiveDate'>
  & { payout?: Maybe<(
    { __typename?: 'Payout' }
    & Pick<Payout, 'createdAt'>
  )>, venue?: Maybe<(
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  )> }
);

export type SpecialItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type SpecialItemsQuery = (
  { __typename?: 'Query' }
  & { specialItems: Array<(
    { __typename?: 'SpecialItem' }
    & SpecialItemPartsFragment
  )> }
);

export type AddOrUpdateSpecialItemMutationVariables = Exact<{
  data: SpecialItemInput;
}>;


export type AddOrUpdateSpecialItemMutation = (
  { __typename?: 'Mutation' }
  & { addOrUpdateSpecialItem: (
    { __typename?: 'SpecialItem' }
    & SpecialItemPartsFragment
  ) }
);

export type VenueWithSpecialItemsQueryVariables = Exact<{
  venueId: Scalars['String'];
}>;


export type VenueWithSpecialItemsQuery = (
  { __typename?: 'Query' }
  & { venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
    & { specialItems: Array<(
      { __typename?: 'SpecialItem' }
      & SpecialItemPartsFragment
    )> }
  ) }
);

export type SpecialItemQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  venueId: Scalars['String'];
}>;


export type SpecialItemQuery = (
  { __typename?: 'Query' }
  & { specialItem?: Maybe<(
    { __typename?: 'SpecialItem' }
    & SpecialItemPartsFragment
  )>, venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'id' | 'name'>
  ) }
);

export type UpdateVenueMutationVariables = Exact<{
  data: VenueUpdateInput;
}>;


export type UpdateVenueMutation = (
  { __typename?: 'Mutation' }
  & { updateVenue: (
    { __typename?: 'Venue' }
    & VenuePartsFragment
  ) }
);

export type VenuePartsFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'id' | 'name' | 'email' | 'payable' | 'address' | 'contactNumber' | 'normalFeePercentage' | 'quenchFeePercentage' | 'quenchPortionPercentage' | 'normalFeeFixed' | 'quenchFeeFixed' | 'quenchFlatFee' | 'refundFee' | 'minimumFee' | 'fixedFeeThreshold' | 'minimumFeeEnabled' | 'bankAccountHoldersName' | 'bankAccountNumber' | 'eftPayable' | 'branchCode' | 'payoutProcessor' | 'vatNumber'>
);

export type VenueQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VenueQuery = (
  { __typename?: 'Query' }
  & { venue: (
    { __typename?: 'Venue' }
    & VenuePartsFragment
  ) }
);

export type VenuesQueryVariables = Exact<{ [key: string]: never; }>;


export type VenuesQuery = (
  { __typename?: 'Query' }
  & { venues: Array<(
    { __typename?: 'Venue' }
    & VenuePartsFragment
  )> }
);

export const InvoicePartsFragmentDoc = gql`
    fragment InvoiceParts on Invoice {
  id
  toDate
  venue {
    id
    name
  }
}
    `;
export const PayoutPartsFragmentDoc = gql`
    fragment PayoutParts on Payout {
  id
  toDate
  batchId
  totalInCents
  venue {
    id
    name
  }
}
    `;
export const PeachPayoutPartsFragmentDoc = gql`
    fragment PeachPayoutParts on PeachPayout {
  id
  batchId
  fileName
  createdAt
}
    `;
export const SpecialItemPartsFragmentDoc = gql`
    fragment SpecialItemParts on SpecialItem {
  id
  name
  specialItemKind
  shortName
  description
  taxable
  totalInCents
  quantity
  createdAt
  effectiveDate
  payout {
    createdAt
  }
  venue {
    id
    name
  }
}
    `;
export const VenuePartsFragmentDoc = gql`
    fragment VenueParts on Venue {
  id
  name
  email
  payable
  address
  contactNumber
  normalFeePercentage
  quenchFeePercentage
  quenchPortionPercentage
  normalFeeFixed
  quenchFeeFixed
  quenchFlatFee
  refundFee
  minimumFee
  fixedFeeThreshold
  minimumFeeEnabled
  bankAccountHoldersName
  bankAccountNumber
  eftPayable
  branchCode
  payoutProcessor
  vatNumber
}
    `;
export const InvoicesDocument = gql`
    query invoices {
  invoices {
    ...InvoiceParts
  }
}
    ${InvoicePartsFragmentDoc}`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const UserSignInDocument = gql`
    mutation userSignIn($email: String!, $password: String!) {
  userSignIn(email: $email, password: $password) {
    token
  }
}
    `;
export type UserSignInMutationFn = Apollo.MutationFunction<UserSignInMutation, UserSignInMutationVariables>;

/**
 * __useUserSignInMutation__
 *
 * To run a mutation, you first call `useUserSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignInMutation, { data, loading, error }] = useUserSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserSignInMutation(baseOptions?: Apollo.MutationHookOptions<UserSignInMutation, UserSignInMutationVariables>) {
        return Apollo.useMutation<UserSignInMutation, UserSignInMutationVariables>(UserSignInDocument, baseOptions);
      }
export type UserSignInMutationHookResult = ReturnType<typeof useUserSignInMutation>;
export type UserSignInMutationResult = Apollo.MutationResult<UserSignInMutation>;
export type UserSignInMutationOptions = Apollo.BaseMutationOptions<UserSignInMutation, UserSignInMutationVariables>;
export const DoPayoutsDocument = gql`
    mutation doPayouts($data: DoPayoutsInput!) {
  doPayouts(data: $data) {
    ...PayoutParts
  }
}
    ${PayoutPartsFragmentDoc}`;
export type DoPayoutsMutationFn = Apollo.MutationFunction<DoPayoutsMutation, DoPayoutsMutationVariables>;

/**
 * __useDoPayoutsMutation__
 *
 * To run a mutation, you first call `useDoPayoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoPayoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doPayoutsMutation, { data, loading, error }] = useDoPayoutsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDoPayoutsMutation(baseOptions?: Apollo.MutationHookOptions<DoPayoutsMutation, DoPayoutsMutationVariables>) {
        return Apollo.useMutation<DoPayoutsMutation, DoPayoutsMutationVariables>(DoPayoutsDocument, baseOptions);
      }
export type DoPayoutsMutationHookResult = ReturnType<typeof useDoPayoutsMutation>;
export type DoPayoutsMutationResult = Apollo.MutationResult<DoPayoutsMutation>;
export type DoPayoutsMutationOptions = Apollo.BaseMutationOptions<DoPayoutsMutation, DoPayoutsMutationVariables>;
export const PayoutsDocument = gql`
    query payouts {
  payouts {
    ...PayoutParts
  }
}
    ${PayoutPartsFragmentDoc}`;

/**
 * __usePayoutsQuery__
 *
 * To run a query within a React component, call `usePayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayoutsQuery(baseOptions?: Apollo.QueryHookOptions<PayoutsQuery, PayoutsQueryVariables>) {
        return Apollo.useQuery<PayoutsQuery, PayoutsQueryVariables>(PayoutsDocument, baseOptions);
      }
export function usePayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutsQuery, PayoutsQueryVariables>) {
          return Apollo.useLazyQuery<PayoutsQuery, PayoutsQueryVariables>(PayoutsDocument, baseOptions);
        }
export type PayoutsQueryHookResult = ReturnType<typeof usePayoutsQuery>;
export type PayoutsLazyQueryHookResult = ReturnType<typeof usePayoutsLazyQuery>;
export type PayoutsQueryResult = Apollo.QueryResult<PayoutsQuery, PayoutsQueryVariables>;
export const PeachPayoutsDocument = gql`
    query peachPayouts {
  peachPayouts {
    ...PeachPayoutParts
  }
}
    ${PeachPayoutPartsFragmentDoc}`;

/**
 * __usePeachPayoutsQuery__
 *
 * To run a query within a React component, call `usePeachPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeachPayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeachPayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeachPayoutsQuery(baseOptions?: Apollo.QueryHookOptions<PeachPayoutsQuery, PeachPayoutsQueryVariables>) {
        return Apollo.useQuery<PeachPayoutsQuery, PeachPayoutsQueryVariables>(PeachPayoutsDocument, baseOptions);
      }
export function usePeachPayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeachPayoutsQuery, PeachPayoutsQueryVariables>) {
          return Apollo.useLazyQuery<PeachPayoutsQuery, PeachPayoutsQueryVariables>(PeachPayoutsDocument, baseOptions);
        }
export type PeachPayoutsQueryHookResult = ReturnType<typeof usePeachPayoutsQuery>;
export type PeachPayoutsLazyQueryHookResult = ReturnType<typeof usePeachPayoutsLazyQuery>;
export type PeachPayoutsQueryResult = Apollo.QueryResult<PeachPayoutsQuery, PeachPayoutsQueryVariables>;
export const OrdersDocument = gql`
    query orders {
  orders {
    id
    totalInCents
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const SpecialItemsDocument = gql`
    query specialItems {
  specialItems {
    ...SpecialItemParts
  }
}
    ${SpecialItemPartsFragmentDoc}`;

/**
 * __useSpecialItemsQuery__
 *
 * To run a query within a React component, call `useSpecialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpecialItemsQuery(baseOptions?: Apollo.QueryHookOptions<SpecialItemsQuery, SpecialItemsQueryVariables>) {
        return Apollo.useQuery<SpecialItemsQuery, SpecialItemsQueryVariables>(SpecialItemsDocument, baseOptions);
      }
export function useSpecialItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialItemsQuery, SpecialItemsQueryVariables>) {
          return Apollo.useLazyQuery<SpecialItemsQuery, SpecialItemsQueryVariables>(SpecialItemsDocument, baseOptions);
        }
export type SpecialItemsQueryHookResult = ReturnType<typeof useSpecialItemsQuery>;
export type SpecialItemsLazyQueryHookResult = ReturnType<typeof useSpecialItemsLazyQuery>;
export type SpecialItemsQueryResult = Apollo.QueryResult<SpecialItemsQuery, SpecialItemsQueryVariables>;
export const AddOrUpdateSpecialItemDocument = gql`
    mutation addOrUpdateSpecialItem($data: SpecialItemInput!) {
  addOrUpdateSpecialItem(data: $data) {
    ...SpecialItemParts
  }
}
    ${SpecialItemPartsFragmentDoc}`;
export type AddOrUpdateSpecialItemMutationFn = Apollo.MutationFunction<AddOrUpdateSpecialItemMutation, AddOrUpdateSpecialItemMutationVariables>;

/**
 * __useAddOrUpdateSpecialItemMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateSpecialItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateSpecialItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateSpecialItemMutation, { data, loading, error }] = useAddOrUpdateSpecialItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOrUpdateSpecialItemMutation(baseOptions?: Apollo.MutationHookOptions<AddOrUpdateSpecialItemMutation, AddOrUpdateSpecialItemMutationVariables>) {
        return Apollo.useMutation<AddOrUpdateSpecialItemMutation, AddOrUpdateSpecialItemMutationVariables>(AddOrUpdateSpecialItemDocument, baseOptions);
      }
export type AddOrUpdateSpecialItemMutationHookResult = ReturnType<typeof useAddOrUpdateSpecialItemMutation>;
export type AddOrUpdateSpecialItemMutationResult = Apollo.MutationResult<AddOrUpdateSpecialItemMutation>;
export type AddOrUpdateSpecialItemMutationOptions = Apollo.BaseMutationOptions<AddOrUpdateSpecialItemMutation, AddOrUpdateSpecialItemMutationVariables>;
export const VenueWithSpecialItemsDocument = gql`
    query venueWithSpecialItems($venueId: String!) {
  venue(id: $venueId) {
    id
    name
    specialItems {
      ...SpecialItemParts
    }
  }
}
    ${SpecialItemPartsFragmentDoc}`;

/**
 * __useVenueWithSpecialItemsQuery__
 *
 * To run a query within a React component, call `useVenueWithSpecialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueWithSpecialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueWithSpecialItemsQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useVenueWithSpecialItemsQuery(baseOptions: Apollo.QueryHookOptions<VenueWithSpecialItemsQuery, VenueWithSpecialItemsQueryVariables>) {
        return Apollo.useQuery<VenueWithSpecialItemsQuery, VenueWithSpecialItemsQueryVariables>(VenueWithSpecialItemsDocument, baseOptions);
      }
export function useVenueWithSpecialItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenueWithSpecialItemsQuery, VenueWithSpecialItemsQueryVariables>) {
          return Apollo.useLazyQuery<VenueWithSpecialItemsQuery, VenueWithSpecialItemsQueryVariables>(VenueWithSpecialItemsDocument, baseOptions);
        }
export type VenueWithSpecialItemsQueryHookResult = ReturnType<typeof useVenueWithSpecialItemsQuery>;
export type VenueWithSpecialItemsLazyQueryHookResult = ReturnType<typeof useVenueWithSpecialItemsLazyQuery>;
export type VenueWithSpecialItemsQueryResult = Apollo.QueryResult<VenueWithSpecialItemsQuery, VenueWithSpecialItemsQueryVariables>;
export const SpecialItemDocument = gql`
    query specialItem($id: String, $venueId: String!) {
  specialItem(id: $id) {
    ...SpecialItemParts
  }
  venue(id: $venueId) {
    id
    name
  }
}
    ${SpecialItemPartsFragmentDoc}`;

/**
 * __useSpecialItemQuery__
 *
 * To run a query within a React component, call `useSpecialItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useSpecialItemQuery(baseOptions: Apollo.QueryHookOptions<SpecialItemQuery, SpecialItemQueryVariables>) {
        return Apollo.useQuery<SpecialItemQuery, SpecialItemQueryVariables>(SpecialItemDocument, baseOptions);
      }
export function useSpecialItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialItemQuery, SpecialItemQueryVariables>) {
          return Apollo.useLazyQuery<SpecialItemQuery, SpecialItemQueryVariables>(SpecialItemDocument, baseOptions);
        }
export type SpecialItemQueryHookResult = ReturnType<typeof useSpecialItemQuery>;
export type SpecialItemLazyQueryHookResult = ReturnType<typeof useSpecialItemLazyQuery>;
export type SpecialItemQueryResult = Apollo.QueryResult<SpecialItemQuery, SpecialItemQueryVariables>;
export const UpdateVenueDocument = gql`
    mutation updateVenue($data: VenueUpdateInput!) {
  updateVenue(data: $data) {
    ...VenueParts
  }
}
    ${VenuePartsFragmentDoc}`;
export type UpdateVenueMutationFn = Apollo.MutationFunction<UpdateVenueMutation, UpdateVenueMutationVariables>;

/**
 * __useUpdateVenueMutation__
 *
 * To run a mutation, you first call `useUpdateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueMutation, { data, loading, error }] = useUpdateVenueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVenueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVenueMutation, UpdateVenueMutationVariables>) {
        return Apollo.useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(UpdateVenueDocument, baseOptions);
      }
export type UpdateVenueMutationHookResult = ReturnType<typeof useUpdateVenueMutation>;
export type UpdateVenueMutationResult = Apollo.MutationResult<UpdateVenueMutation>;
export type UpdateVenueMutationOptions = Apollo.BaseMutationOptions<UpdateVenueMutation, UpdateVenueMutationVariables>;
export const VenueDocument = gql`
    query venue($id: String!) {
  venue(id: $id) {
    ...VenueParts
  }
}
    ${VenuePartsFragmentDoc}`;

/**
 * __useVenueQuery__
 *
 * To run a query within a React component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVenueQuery(baseOptions: Apollo.QueryHookOptions<VenueQuery, VenueQueryVariables>) {
        return Apollo.useQuery<VenueQuery, VenueQueryVariables>(VenueDocument, baseOptions);
      }
export function useVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenueQuery, VenueQueryVariables>) {
          return Apollo.useLazyQuery<VenueQuery, VenueQueryVariables>(VenueDocument, baseOptions);
        }
export type VenueQueryHookResult = ReturnType<typeof useVenueQuery>;
export type VenueLazyQueryHookResult = ReturnType<typeof useVenueLazyQuery>;
export type VenueQueryResult = Apollo.QueryResult<VenueQuery, VenueQueryVariables>;
export const VenuesDocument = gql`
    query venues {
  venues {
    ...VenueParts
  }
}
    ${VenuePartsFragmentDoc}`;

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenuesQuery(baseOptions?: Apollo.QueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
        return Apollo.useQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, baseOptions);
      }
export function useVenuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VenuesQuery, VenuesQueryVariables>) {
          return Apollo.useLazyQuery<VenuesQuery, VenuesQueryVariables>(VenuesDocument, baseOptions);
        }
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>;
export type VenuesLazyQueryHookResult = ReturnType<typeof useVenuesLazyQuery>;
export type VenuesQueryResult = Apollo.QueryResult<VenuesQuery, VenuesQueryVariables>;