import { useApolloClient } from '@apollo/client'
import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { Display4 } from 'baseui/typography'
import { PeachBatches } from 'pages/Payout/PeachBatches'
import { SpecialItems } from 'pages/SpecialItems/SpecialItems'
import { VenueSpecialItem } from 'pages/Venue/SpecialItems/SpecialItem'
import { VenueSpecialItems } from 'pages/Venue/SpecialItems/SpecialItems'
import * as React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { clearToken } from 'services/auth'

import { Invoices } from '../../pages/Invoice/Invoices'
import { CreatePayout } from '../../pages/Payout/CreatePayout'
import { Payouts } from '../../pages/Payout/Payouts'
import { Report } from '../../pages/Report/Report'
import { Venue } from '../../pages/Venue/Venue'
import { Venues } from '../../pages/Venue/Venues'
import { SideBar } from '../SideBar/SideBar'

interface MainLayoutProps {
  title?: string
}

const MainLayout: React.FC<MainLayoutProps> = ({ title }) => {
  const history = useHistory()
  const client = useApolloClient()
  const handleLogout = () => {
    clearToken()
    client.resetStore()
    history.push('/login')
  }
  return (
    <Block
      overrides={{
        Block: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundTertiary,
          }),
        },
      }}
    >
      <Block
        height="100%"
        width="280px"
        position="fixed"
        left="0"
        top="0"
        bottom="0"
        display="block"
      >
        <SideBar />
      </Block>
      <Block
        as="main"
        height="100vh"
        display="flex"
        flexDirection="column"
        paddingLeft="280px"
        overflow="scrollY"
      >
        <Block
          as="header"
          width="100%"
          minHeight="60px"
          display="flex"
          alignItems="center"
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.backgroundPrimary,
              }),
            },
          }}
        >
          <Display4>{title}</Display4>
          <Block
            as="div"
            overrides={{
              Block: {
                style: () => ({
                  flexGrow: 1,
                }),
              },
            }}
          ></Block>
          <Button
            onClick={handleLogout}
            overrides={{
              Root: {
                style: () => ({
                  marginRight: '10px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: 'auto',
                }),
              },
            }}
          >
            Logout
          </Button>
        </Block>
        <Block height="100%">
          <Switch>
            <Route path={'/admin/reports'}>
              <Report />
            </Route>
            <Route
              path={'/admin/venues/:venueId/special-items/edit/:specialItemId'}
            >
              <VenueSpecialItem />
            </Route>
            <Route path={'/admin/venues/:venueId/special-items/add'}>
              <VenueSpecialItem />
            </Route>
            <Route path={'/admin/venues/:venueId/special-items'}>
              <VenueSpecialItems />
            </Route>
            <Route path={'/admin/venues/:venueId'}>
              <Venue />
            </Route>
            <Route path={'/admin/venues'}>
              <Venues />
            </Route>
            <Route path={'/admin/invoices'}>
              <Invoices />
            </Route>
            <Route path={'/admin/payouts/peach'}>
              <PeachBatches />
            </Route>
            <Route path={'/admin/payouts/create'}>
              <CreatePayout />
            </Route>
            <Route path={'/admin/payouts'}>
              <Payouts />
            </Route>
            <Route path={'/admin/special-items'}>
              <SpecialItems />
            </Route>
          </Switch>
        </Block>
      </Block>
    </Block>
  )
}

export { MainLayout }
