import { Block } from 'baseui/block'
import { AccountingReport } from 'components/Reports/AccountingReport'
import { SpecialItemReport } from 'components/Reports/SpecialItemReport'
import * as React from 'react'

const Report: React.FC = () => {
  return (
    <Block height="100%">
      <AccountingReport />
      <SpecialItemReport />
    </Block>
  )
}

export { Report }
