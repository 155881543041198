import { LinkProps, StyledLink } from 'baseui/link'
import * as React from 'react'

interface TableButtonProps extends LinkProps {
  title: string
  href: string
}

const TableButton: React.FC<TableButtonProps> = ({ title, href, target }) => {
  return (
    <StyledLink href={href} target={target}>
      {title}
    </StyledLink>
  )
}

export { TableButton }
