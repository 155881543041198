import { Button } from 'baseui/button'
import { StyledLink } from 'baseui/link'
import * as React from 'react'

interface SideBarButtonProps {
  title: string
  href: string
}

const SideBarButton: React.FC<SideBarButtonProps> = ({ title, href }) => {
  return (
    <Button
      kind="tertiary"
      overrides={{
        Root: {
          style: {
            width: '100%',
          },
        },
      }}
    >
      <StyledLink href={href}>{title}</StyledLink>
    </Button>
  )
}

export { SideBarButton }
