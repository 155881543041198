import {
  Checkbox,
  CheckboxProps,
  LABEL_PLACEMENT,
  STYLE_TYPE,
} from 'baseui/checkbox'
import { FormControl, FormControlOverrides } from 'baseui/form-control'
import { useField } from 'formik'
import React from 'react'

import { FormikControlProps } from './FormikControl'

export type CheckboxFieldProps = CheckboxProps &
  FormikControlProps & {
    formControlOverrides?: FormControlOverrides
  }

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  caption,
  disabled,
  formControlOverrides,
  ...checkboxProps
}) => {
  const [field, meta] = useField(name)
  const hasError = !!meta.error
  return (
    <FormControl
      disabled={disabled}
      caption={caption}
      error={hasError ? meta.error : null}
      overrides={formControlOverrides}
    >
      <Checkbox
        {...field}
        disabled={disabled}
        checked={field.value}
        checkmarkType={STYLE_TYPE.toggle_round}
        labelPlacement={LABEL_PLACEMENT.right}
        {...checkboxProps}
        isError={hasError}
      >
        {label}
      </Checkbox>
    </FormControl>
  )
}
