import { Block } from 'baseui/block'
import { Button } from 'baseui/button'
import { toaster } from 'baseui/toast'
import { Form, Formik, Yup } from 'components/Form'
import { DateField, DEFAULT_DATE_FORMAT } from 'components/Form/DateField'
import { format, subDays } from 'date-fns'
import { useDoPayoutsMutation } from 'lib/gql'
import * as React from 'react'

const PayoutRequestSchema = Yup.object()
  .noUnknown(false)
  .shape({
    endDate: Yup.date().max(subDays(new Date(), 1)).required('Required'),
  })

type PayoutRequestInput = {
  endDate: string
}

const CreatePayout: React.FC = () => {
  const [generatingPayout, setGeneratingPayout] = React.useState(false)
  const [doPayouts] = useDoPayoutsMutation()

  const handleSubmit = async (payoutRequest: PayoutRequestInput) => {
    setGeneratingPayout(true)
    doPayouts({
      variables: {
        data: {
          endDate: payoutRequest.endDate,
        },
      },
    })
      .then(() => {
        setGeneratingPayout(false)
        toaster.positive('Payouts Created', {})
      })
      .catch((error) => {
        setGeneratingPayout(false)
        // eslint-disable-next-line no-console
        console.error(error)
        toaster.negative(JSON.stringify(error), {})
      })
  }

  const initialValues = {
    endDate: format(subDays(new Date(), 1), DEFAULT_DATE_FORMAT),
  } as PayoutRequestInput

  return (
    <Block height="100%">
      <Formik<PayoutRequestInput>
        onSubmit={handleSubmit}
        validationSchema={PayoutRequestSchema}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form>
            <DateField name="endDate" label="End Date" />
            <Button
              isLoading={isSubmitting}
              disabled={generatingPayout}
              type="submit"
            >
              Create Payout
            </Button>
          </Form>
        )}
      </Formik>
    </Block>
  )
}

export { CreatePayout }
