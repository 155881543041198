export function titleize(input: string) {
  return input
    .split(' ')
    .map(
      (word) => word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase(),
    )
    .join(' ')
}

// replace underscores with spaces and convert to Leading capital
export function humanize(input: string) {
  const replaced = input
    .trim()
    .replace(/[_]+/g, ' ') // snake_case
    .replace(/([a-z])([A-Z])/g, (_, first, second) => `${first} ${second}`) // camelCase
    // some "humanizing" replacements
    .replace(/\bpick up\b/i, 'pickup')

  return (
    replaced.substr(0, 1).toUpperCase() + replaced.substr(1).toLowerCase()
  ).replace(/\bpos\b/i, 'POS')
}

export function truncate(input: string, maxLength: number, ellipsis = '…') {
  if (input.length > maxLength) {
    return input.substring(0, maxLength).concat(ellipsis)
  }
  return input
}

export const uuidRegex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/
